import React, { ReactNode } from "react";
import "../styles/contactus.scss";
export class ContactUs extends React.Component{
    render(): ReactNode {
        return (
            <section className="headerinfo bg">
                <div className="contactUs">
                    <div className="contactUs_title btn_title">联系我们</div>
                    <div className="contactUs_content">
                        <p>参观咨询及活动预约：</p>
                        <p>参观咨询热线：</p>
                        <p>盱眙县博物馆：0517-8211424（周二至周日，9：00-17：00）。</p>
                        <p>学校或教育机构至博物馆举办各类教育活动，请发送传真至0512-67544232，或发送到电子邮箱gzjyb@szmuseum.com，由公众教育部安排活动事宜。</p>
                        <p>请需要联系实习的在校学生致电人事财务部：0512-67576020（周一至周五，9：00-17：00），或将个人简历发送至Email：hr@szmuseum.com。</p>
                    </div>
                </div>
            </section>
        )
    }
  
}