import "../styles/info.scss";
import React, { MouseEventHandler } from "react";
import { Link, Route, RouteComponentProps } from "react-router-dom";

import { Switch } from "react-router";
import { Oration } from "./infos/oration";
import { Orgnization } from "./infos/orgnization";

import { Publisher } from "./infos/publisher";
import { News } from "./infos/News";
import { PublishDetial } from "./infos/publishdetial";
import { withRouter } from "react-router-dom";
import { Menu } from "../models/system";
import { MenuSwitcher } from "../libs/MenuSwitcher";
//@ts-ignore

@withRouter
export class Info extends React.Component<Partial<RouteComponentProps>> {
    private getMenus = (): Menu[] => [
        { title: "盱眙简介", src: "/views/infos" },
        { title: "组织架构", src: "/views/infos/orgnization" },
        { title: "发布信息", src: "/views/infos/Publisher" },
        { title: "最新动态", src: "/views/infos/news" },

    ];
    private menu: JSX.Element | null = null;
    private menuSwitcher: MenuSwitcher = new MenuSwitcher("choiced", this.getMenus(), "_", "/views/infos");


    componentDidMount(): void {
        this.menuSwitcher.load();

    }
    render() {

        return (
            <section className="headerinfo info bg">
                <nav>
                    <div>
                        {this.menu = this.menu = this.menuSwitcher.craeteMenu()}

                    </div>
                </nav>
                <div className="article">
                    <Switch>
                        <Route path={"/views/infos"} exact component={Oration}></Route>
                        <Route path={"/views/infos/orgnization"} exact component={Orgnization}></Route>
                        <Route path={"/views/infos/Publisher"} exact component={Publisher}></Route>
                        <Route path={"/views/infos/publishdetial/:id"} exact component={PublishDetial}></Route>
                        <Route path={"/views/infos/news"} exact component={News}></Route>

                    </Switch>
                </div>
            </section>
        );
    }
}