import React, { ReactNode } from "react";
export class TitleInfo{
    title: string;
    constructor(title: string){
        this.title = title;
    }
}
export class Title extends React.Component<TitleInfo>{
    render(): ReactNode{
        return(
            <div className="title">
               <div> 
                <span className="sect_icon left"></span>{this.props.title}<span className="sect_icon right"></span>
                </div>
                <div>{this.props.children}</div>
            </div>
        )
    }
}