import "../../styles/services.scss";
import React from "react";
import { ReactNode } from "react";
import { SubmenuTitle } from "../components/SubmenuTitle";
import ProductImage from "../../assets/images/creates/ps.png";
import { getProducts } from "../../apis/api";
import { ProductInfo } from "../../models/system";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Pager } from "../components/Pager";
//@ts-ignore
@withRouter
export class Product extends React.Component<Partial<RouteComponentProps<any>>> {

    state = { data: [] as ProductInfo[], page: 0, size: 9, total: 0 }
    constructor(props: any) {
        super(props);
        getProducts(this.state.page, this.state.size).then((data) => {
            this.setState({ data: data.content, total: data.pageCount / 9 });
        })
    }


    async showPage(page: number) {
        await getProducts(page, this.state.size).then((data) => {
            this.setState({ data: data.content });
        })
    }

    showDeital(id: string) {
        this.props.history!.push("/views/creates/detialProduct/" + id);
    }

    render(): ReactNode {
        return (
            <>
                <SubmenuTitle title="文创产品"></SubmenuTitle>
                <div className="product">
                    {
                        this.state.data?.map((item, index) =>

                            <div onClick={() => this.showDeital(item.id.toString())}>
                                <div className="img">
                                    <img src={item?.pic ?? ProductImage}></img>
                                </div>
                                <div className="infos">
                                    <span className="name">{item?.title}</span>
                                    <span className="price">{item?.price}</span>
                                </div>
                            </div>
                        )
                    }

                </div>

                <Pager pageSize={9} pageCount={this.state.total} curpage={0} onNavigatePage={o => this.showPage(o)} ></Pager>
            </>
        )
    }
}