import React, { ReactNode } from "react";
import { SubmenuTitle } from "../components/SubmenuTitle";
export class Home extends React.Component {
    render(): ReactNode {
        return (
            <>
                <SubmenuTitle title="志愿者之家"></SubmenuTitle>
                <div className="home">
                    <video></video>
                    <div className="home_item">
                        <div className="itemTitle">志愿者章程和管理细则详细</div>
                        <div className="rule"></div>
                    </div>
                    <div className="home_item">
                        <div className="itemTitle">志愿者附件下载</div>
                        <div className="download">
                            <span>盱眙博物馆志愿者报名表</span>
                            <span className="download"><a href="/志愿者章程和管理细则详细.docx" className="attach">附件下载</a></span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}