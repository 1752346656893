import "../styles/services.scss";
import React, { MouseEventHandler } from "react";
import { ReactNode } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Education } from "./services/Education";
import { Talking } from "./services/Talking";
import { Video } from "./services/Video";
import { Home } from "./services/Home";
import { Orgnization } from "./infos/orgnization";
import { Menu } from "../models/system";
import { MenuSwitcher } from "../libs/MenuSwitcher";


export class Service extends React.Component {

    private getMenus = (): Menu[] => [
        { title: "教育活动", src: "/views/services/1/教育活动" },
        { title: "文博讲座", src: "/views/services/2/文博讲座" },
        { title: "志愿者之家", src: "/views/services/home" },
        { title: "精彩视频", src: "/views/services/video" },
    ];
    private menuSwitcher: MenuSwitcher = new MenuSwitcher("choiced", this.getMenus(), "service_", "/views/services/1/教育活动");
    render(): ReactNode {
        return (
            <section className="headerinfo info bg">
                <nav>
                    <div>
                        {this.menuSwitcher.craeteMenu()}
                    </div>
                </nav>
                <div className="article" id="service">
                    <Switch>
                        <Route path={"/views/services/:type/:name"} exact component={Education}></Route>
                        <Route path={"/views/services/home"} exact component={Home}></Route>
                        <Route path={"/views/services/video"} exact component={Video}></Route>
                    </Switch>
                </div>
            </section>

        )

    }
}