import React from "react";


export interface PageInfo {
    /**
     * 总页数
     */
    pageCount: number,
    /**
     * 每页显示的记录数
     */
    pageSize: number,
    curpage: number,
    onNavigatePage: (page: number) => void
}
export class Pager extends React.Component<PageInfo> {
    state = { curpage: 0, start: 0, end: 0 };

    public navigateToPage: (page: number) => void = (page: number) => {


        this.props.onNavigatePage(page);
        this.setState({ curpage: page });

    };


    public prev_Click: () => void = () => {
        if (this.state.curpage > 0) {

            this.navigateToPage(this.state.curpage - 1);
        }
    };

    public next_Click: () => void = () => {
        if (this.state.curpage < this.props.pageCount - 1) {

            this.navigateToPage(this.state.curpage + 1);
        }
    };

    createPageList(): JSX.Element[] {
        let list: JSX.Element[] = [];
        for (let i = 0; i < this.props.pageCount + 0.000001; i++) {
            list.push(<a className="circle" onClick={() => this.navigateToPage(i)}>{i + 1}</a>);
        }
        return list;
    }
    render() {
        return (
            <div className="page">
                {/* <a className="rect" onClick={() => this.navigateToPage(0)}>首页</a> */}
                <a className="rect" onClick={this.prev_Click}>&lt;</a>
                {this.createPageList()}
                <a className="rect" onClick={this.next_Click}>&gt;</a>
                {/* <a className="rect" onClick={() => this.navigateToPage(this.props.pageCount - 1)}>尾页</a> */}
            </div>
        );
    }
}