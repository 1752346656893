import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Product } from "./creates/product";
import "../styles/create.scss";
import { DetialProduct } from "./creates/detialProduct";
import { Shop} from "./creates/shop"
export class Create extends React.Component {
    render() {
        return (
            <section className="headerinfo info bg">
            <nav>
                <div>
                    <Link to={"/views/creates"}>文创产品</Link>
                    <Link to={"/views/creates/shop"}>商店介绍</Link>
                
                </div>
            </nav>
            <div className="article" id="create">
                <Switch>
                    <Route path={"/views/creates/detialProduct/:id"} component={DetialProduct}></Route>
                    <Route path={"/views/creates"} exact component={Product}></Route>
                    <Route path={"/views/creates/shop"} exact component={Shop}></Route>
                </Switch>
            </div>
        </section>
        );
    }
}