import React from "react";
import "../styles/search.scss";
import { ReactNode } from "react";
import SearchLogo from "./../assets/images/search_log.png";
import { Link } from "react-router-dom";
export class Search extends React.Component {
    render(): ReactNode {
        return (

            <section className="headerinfo bg">
                <style>
                    {`
                    .search_type>span>a{
                        text-decoration: none;
                    `}
                </style>
                <div className="search">
                    <img src={SearchLogo} />
                    <div className="search_type">
                        <span><Link to="/" >首页</Link></span>
                        <span><Link to={"/views/infos"}>资讯</Link></span>
                        <span><Link to={"/views/shows"}> 展览</Link></span>
                        <span><Link to={"/views/saving"}> 馆藏</Link></span>
                        <span><Link to={"/views/services/1/教育活动"}>服务</Link></span>
                        <span><Link to={"/views/creates"}>文创</Link></span>
                    </div>
                    <div className="search_input">
                        <input type="text" placeholder="请输入搜索内容"></input>
                        <div className="search_icon">搜索</div>
                    </div>

                </div>
            </section>
        )
    }
}