import React, { Fragment, ReactNode } from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getEduAcvities } from "../../apis/api";
import { ActivityInfo } from "../../models/system";
import { SubmenuTitle } from "../components/SubmenuTitle";
//@ts-ignore
@withRouter

export class Education extends React.Component<Partial<RouteComponentProps<{ type: string, name: string }>>> {

    state = { page: 0, pageSize: 10, list: [] as ActivityInfo[] }

    constructor(props: any) {
        super(props);
        getEduAcvities(this.state.page, this.state.pageSize, parseInt(this.props.match?.params?.type ?? "1")).then((res) => {
            this.setState({ list: res.content });
        })

    }
    async componentWillReceiveProps(nextProps: Readonly<Partial<RouteComponentProps<{ type: string; name: string; }, StaticContext, unknown>>>, nextContext: any) {
        let res = await getEduAcvities(this.state.page, this.state.pageSize, parseInt(nextProps.match?.params?.type ?? "1"));
        this.setState({ list: res.content });
        document.querySelector(".edu")?.scrollTo(0, 0);
        // document.querySelector(".edu")?.querySelectorAll(".content")?.forEach((x: HTMLElement, k, p) => x.classList.add("content"));

    }

    render(): ReactNode {

        return (
            <>
                <SubmenuTitle title={this.props.match?.params?.name ?? ""} />
                <div className="edu">
                    {
                        this.state!.list!.map((item, index) =>
                            <div className="item">
                                <div className="img"><img src={item.pic} /></div>
                                <div className="text">
                                    <h3><span>{item.title}</span></h3>
                                    <p>时间：{item.date}</p>
                                    <p>地点：{item.address}</p>
                                    <p>主办：{item.org}</p>
                                    {item.recvorg && <p>承办：{item.recvorg}</p>}
                                    {item.assist && <p>协办：{item.assist}</p>}
                                    <p>参与对象：{item.topeople}</p>
                                    <p className="content" id={"edu_" + index} dangerouslySetInnerHTML={{ __html: `<span>活动内容：</span>${item.content}` }}></p>

                                    <div className="btn enabled" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.currentTarget.parentElement?.querySelector(".content")?.classList.remove("content")} ></div>
                                </div>

                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}