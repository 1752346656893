import React from "react";
import { Reactangle } from "./Reactangle";
import leftbar from "../../assets/images/leftbar.png";
import rightbar from "../../assets/images/rightbar.png";
import { HomeInfo, LeaderTalk } from "../../models/system";
export class Oration extends React.Component {

    render(): React.ReactNode {

        
        let content = {} as LeaderTalk;
        if(window.localStorage.getItem("sysinfo")!=null){
            content = JSON.parse(window.localStorage.getItem("sysinfo")!) ;
        }


        return (
            <>
                <div className="title"><img src={leftbar} alt=""></img><span>盱眙简介</span><img src={rightbar} alt="" /></div>
                <Reactangle>
                    <div className="visitor">时间：2022年3月14日</div>
                    <div  dangerouslySetInnerHTML={{__html: content.content}}>
                      
                    </div>
                    <div className="name">盱眙博物馆馆长</div>
                </Reactangle>
            </>
        )
    }
}