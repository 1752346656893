import axios from 'axios';






import { ActivityInfo, CollectionInfo, HomeInfo, pageInfo, ProductInfo, ShowInfo, TitleInfo } from '../models/system';
import { baseUrl } from '../config';
import { Product } from '../views/creates/product';




export const getHomeInfo = async (): Promise<HomeInfo> => {
    let c = await axios.get(baseUrl + '/museum');
    return c.data as HomeInfo;
}

export interface VideoInfo { url: string, title: string, id: number, pic: string, address: string, date: string };

export const getVideos = async (page: number, size: number, catid: number): Promise<pageInfo<VideoInfo>> => {
    let c = await axios.get(baseUrl + '/Videos', { params: { page, size } });

    return c.data as pageInfo<VideoInfo>;
}

export const getArticles = async (page: number, size: number, catid: number): Promise<pageInfo<TitleInfo>> => {
    let c = await axios.get(baseUrl + '/articleList', { params: { page, size, catid } });

    return c.data as pageInfo<TitleInfo>;
}

export const getNews = async (page: number, size: number, catid: number): Promise<TitleInfo[]> => {
    let c = await axios.get(baseUrl + '/news', { params: { page, size, catid } });

    return c.data as TitleInfo[];
}
export const getProducts = async (page: number, size: number): Promise<pageInfo<ProductInfo>> => {
    let c = await axios.get(baseUrl + '/products', { params: { page, size } });
    return c.data as pageInfo<ProductInfo>;
}

export const getProduct = async (id: string): Promise<ProductInfo> => {

    let c = await axios.get(baseUrl + '/product', { params: { id: parseInt(id) } });

    return c.data as ProductInfo;
}
export const getArticle = async (id: string): Promise<TitleInfo> => {

    let c = await axios.get(baseUrl + '/article', { params: { id: parseInt(id) } });

    return c.data as TitleInfo;
}
export const getEduAcvities = async (page: number, size: number, type: number = 1): Promise<pageInfo<ActivityInfo>> => {
    let c = await axios.get(baseUrl + '/activities', { params: { page, size, type } });

    return c.data as pageInfo<ActivityInfo>;
}

export const getCollections = async (type: number, page: number, size: number): Promise<pageInfo<CollectionInfo>> => {
    let c = await axios.get(baseUrl + '/collections', { params: { type, page, size } });
    return c.data as pageInfo<CollectionInfo>;
}


export const getShowings = async (type: number, page: number, size: number) => {
    let c = await axios.get(baseUrl + '/showings', { params: { type, page, size } });
    return c.data as pageInfo<ShowInfo>;

}
export const getCollection = async (id: string): Promise<CollectionInfo> => {

    let c = await axios.get(baseUrl + '/collection', { params: { id: parseInt(id) } });

    return c.data as CollectionInfo;
}