import React from "react";
import { Link } from "react-router-dom";
import "../styles/saving.scss";



export class Saving extends React.Component {
    savingClick = (url: string) => {
    }
    render(): JSX.Element {
        return (
            <section className="bg headerinfo">
                <div className="saving ">
                    <div onClick={()=>document.location.href='/views/saving/savingType/0'} />
                    <div onClick={()=>document.location.href='/views/saving/savingType/1'}/>
                    <div onClick={()=>document.location.href='/views/saving/savingType/2'}/>
                    <div onClick={()=>document.location.href='/views/saving/savingType/3'} />
                    <div onClick={()=>document.location.href='/views/saving/savingType/4'}/>
                    <div onClick={()=>document.location.href='/views/saving/savingType/5'} />
                </div>
            </section>
        )
    }
}