import "../../styles/home.scss";
import React, { ReactNode } from "react";
import { OpenTime } from "./openTime";
import { Title } from "../components/Title";
import more from "../../styles/images/home/more.png";
import edu from "../../styles/images/home/edu.png";
import home from "../../styles/images/home/home.png";
import lesson from "../../styles/images/home/lesson.png";
import china from "../../styles/images/home/china.png";
import { Link } from "react-router-dom";
import { HomeInfo, NewsInfo, TitleInfo } from "../../models/system";
import { getArticle, getArticles, getNews } from "../../apis/api";
export class HomeBody extends React.Component<HomeInfo> {
    state = { start: 0, end: 5, publicInfos: [] as TitleInfo[] };

    handlePrev: () => void = () => {
        if (this.state.start > 0)
            this.setState({ start: this.state.start - 1, end: this.state.end - 1 });
    }
    handleNext: () => void = () => {
        if (this.state.end < this.props.collectionInfos.length)
            this.setState({ start: this.state.start + 1, end: this.state.end + 1 });
    }
    async componentDidMount() {
        let r = await getArticles(0, 10, 50);
        this.setState({ publicInfos: r.content });

    }
    render(): ReactNode {
        return (
            <section className="home bg">
                <div className="top">
                    <div className="left">
                        <div className="lefttop">
                            <OpenTime></OpenTime>
                            <div className="commu content">
                                <Title title="交通指南"></Title>

                                <p><span>公交：</span><span>1路（图书馆站下）;8路、10路（八一广场站下向东南方向步行200米）。</span></p>
                                <p><span>地址：</span><span>江苏省淮安市盱眙县东方大道3号。</span></p>
                                <p className="notice">博物馆门前市民广场停场免费，请按停车引导标识和引导号的指引，将车有序排放。</p>
                            </div>

                        </div>
                        <div className="leftbtm content">
                            <Title title="最新动态">
                                <Link to={"/views/infos/Publisher"}><img src={more} alt="了解更多"></img></Link>

                            </Title>
                            <ul className="content">
                                {
                                    this.state.publicInfos?.map(x => <li><span><Link to={`/views/infos/publishdetial/${x.id}`}>{x.title}</Link></span><span>{x.date}</span></li>)
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="right ">
                        <div className="righttop">
                            <img src={china} alt=""></img>
                            <a href={this.props.virtualUrl}>点击进入</a>
                        </div>
                        <div className="rightbtm content" onClick={() => window.location.href = `/views/shows/`}>

                            <Title title="临时展览"></Title>
                            <div className="container">
                                <div className="caption">
                                    <p>馆藏书画作品展</p>
                                    <p className="small">Exhibition of call graphy and painting<br />works in thie collection.<br /></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="middle ">
                    <div className="midleft content ">
                        <Title title="盱博活动">
                            <Link to={"/views/services"}><img src={edu} alt="了解更多"></img></Link>
                            <Link to={"/views/services/talking"}><img src={lesson} alt="了解更多"></img></Link>
                            <Link to={"/views/services/home"}><img src={home} alt="了解更多"></img></Link>
                        </Title>
                        <ul className="content">
                            {
                                this.props.activities?.map(x => <li><span><Link to={`/views/services/1/教育活动`}>{x.title}</Link></span><span>{x.date}</span></li>)
                            }

                        </ul>
                    </div>

                    <div className="midright content" onClick={() => window.location.href = `/views/shows/temp`}>
                        <Title title="临时展览"></Title>
                    </div>


                </div>
                <div className="bottom ">
                    <div className="title"><span className="sect_icon top"></span><div className="text">馆藏推荐</div><span className="sect_icon bottom"></span></div>
                    <div className="container">
                        <div className="items">

                            {
                                this.props.collectionInfos?.slice(this.state.start, this.state.end).map(o =>
                                    <div><img src={o.pic} alt={o.title}></img>
                                    </div>)
                            }


                        </div>
                        <nav className="prev" onClick={this.handlePrev}></nav>
                        <nav className="next" onClick={this.handleNext}></nav>
                    </div>
                </div>
            </section>
        );
    }
}