import React from "react";

import fullbar from "../../assets/images/fullbar.png";
import { RouteComponentProps,withRouter } from "react-router-dom";
import { getArticle } from "../../apis/api";
import { TitleInfo } from "../../models/system";
//@ts-ignore

@withRouter
export class PublishDetial extends React.Component<Partial<RouteComponentProps<{id:string}>>>{
    state={data:null as null|TitleInfo}
    componentDidMount(): void {
        let id=this.props.match?.params.id??"0";
        getArticle(id).then((res)=>{
            this.setState({data:res});
        });
    }
    render(): React.ReactNode {
       
        return (
            <div className="publish_detial">
                <div className="detialBar"><img src={fullbar} alt=""></img></div>
                <div className="publish_title">{this.state.data?.title}</div>
                <div className="publish_time"><span>时间：{this.state.data?.date}</span><span>浏览次数：{this.state.data?.hits}</span></div>
                <div className="publish_content" dangerouslySetInnerHTML={{__html:this.state.data?.content??""}}></div>
                
            </div>
        );
    }
    
}