import React, { ReactNode } from "react";

import "../styles/message.scss";
import fullbar from "../assets/images/bar1.png";
export class Message extends React.Component{
    render(): ReactNode {
        return (
            <section className="bg headerinfo">
                <div className="message">
                    <div className="message_title btn_title">我要留言</div>
                    <div className="message_input">
                        <div className="message_input_item">
                        <div className="message_input_title">您的姓名：</div>
                        <input type="text" ></input>
                        </div>
                        <div className="message_input_item">
                        <div className="message_input_title">邮箱地址：</div>
                        <input type="text" ></input>
                        </div>
                        <div className="message_input_item">
                        <div className="message_input_title">手机号码：</div>
                        <input type="text" ></input>
                        </div>
                        <div className="message_input_item">
                        <div className="message_input_title">标题：</div>
                        <input type="text" ></input>
                        </div>
                        <div className="message_input_item">
                        <div className="message_input_title">信件内容：</div>
                        <textarea></textarea>
                       
                        </div>
                        <div className="message_input_btn">
                        <input type="button" value="提交"></input>
                        <input type="button" value="重置"></input>
                        </div>
                    </div>
                    <div className="detialBar"><img src={fullbar}></img></div>
                    <div className="message_title_list btn_title">留言列表</div>
                    <div className="message_result">
                        <div className="message_result_item">
                            <p className="name"><span>标题：</span><span>西馆的导览图</span></p>
                            <p className="content"><span>内容：</span><span>你好，是否可以发送一下西馆的导览图？</span></p>
                            <p className="answer"><span>管理员答复：</span><span>西馆的导览图</span></p>
                        </div>


                        <div className="message_result_item">
                            <p className="name"><span>标题：</span><span>西馆的导览图</span></p>
                            <p className="content"><span>内容：</span><span>你好，是否可以发送一下西馆的导览图？</span></p>
                            <p className="answer"><span>管理员答复：</span><span>西馆的导览图</span></p>
                        </div>
                        <div className="message_result_item">
                            <p className="name"><span>标题：</span><span>西馆的导览图</span></p>
                            <p className="content"><span>内容：</span><span>你好，是否可以发送一下西馆的导览图？</span></p>
                            <p className="answer"><span>管理员答复：</span><span>西馆的导览图</span></p>
                        </div>
                        <div className="message_result_item">
                            <p className="name"><span>标题：</span><span>西馆的导览图</span></p>
                            <p className="content"><span>内容：</span><span>你好，是否可以发送一下西馆的导览图？</span></p>
                            <p className="answer"><span>管理员答复：</span><span>西馆的导览图</span></p>
                        </div>
                        <div className="message_result_item">
                            <p className="name"><span>标题：</span><span>西馆的导览图</span></p>
                            <p className="content"><span>内容：</span><span>你好，是否可以发送一下西馆的导览图？</span></p>
                            <p className="answer"><span>管理员答复：</span><span>西馆的导览图</span></p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}