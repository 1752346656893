import "../../styles/services.scss";
import React from "react";
import { ReactNode } from "react";
import { SubmenuTitle } from "../components/SubmenuTitle";
import ProductImage from "../../assets/images/creates/ps.png";
import { getProduct } from "../../apis/api";
import { Link, RouteComponentProps, RouteProps, withRouter } from "react-router-dom";
import { ProductInfo } from "../../models/system";
//@ts-ignore
@withRouter
export class DetialProduct extends React.Component<Partial<RouteComponentProps<{ id: string }>>> {
    state = { data: null as null | ProductInfo }
    componentDidMount(): void {
        getProduct(this.props.match!.params.id).then((data) => {
            this.setState({ data });
        });
    }
    render(): ReactNode {
        return (
            <>
                <SubmenuTitle title="文创产品"></SubmenuTitle>
                <div id="detialProduct">
                    <div className="detialProduct">

                        <div className="mainpic">
                            <img src={this.state.data?.pic}></img>
                        </div>
                        <div className="product_content">
                            <div className="product_name">{this.state.data?.title}</div>
                            <div className="product_price"><label>价格</label><span>{this.state.data?.price ?? ""}</span></div>
                            <div className="btn enabled">立即购买</div>
                            <div className="product_styles">
                                <div><img src={this.state.data?.pic}></img><span>样式一</span></div>
                                <div><img src={this.state.data?.pic}></img><span>样式二</span></div>
                                <div><img src={this.state.data?.pic}></img><span>样式三</span></div>
                                <div><img src={this.state.data?.pic}></img><span>样式四</span></div>
                                <div><img src={this.state.data?.pic}></img><span>样式五</span></div>
                            </div>

                        </div>

                    </div>
                    <div className="product_detail_title redbtn">产品详情</div>
                    <div className="product_detail" dangerouslySetInnerHTML={{ __html: this.state.data?.content ?? "" }}>

                    </div>
                    <Link to={"/views/creates/"}><div className="product_back redbtn">返回</div></Link>
                </div>
            </>
        )
    }
}