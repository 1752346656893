import React from "react";
import { Link } from "react-router-dom";
import { getArticles } from "../../apis/api";
import { pageInfo, TitleInfo } from "../../models/system";
import { SubmenuTitle } from "../components/SubmenuTitle";
import { Pager } from "../components/Pager";


export class Publisher extends React.Component {

    state = { data: null as pageInfo<TitleInfo> | null, curpage: 0, pagecount: 0 };
    static type: number = 59;
    componentDidMount(): void {
        getArticles(this.state.curpage, 10, Publisher.type).then(x => {
            this.setState({ data: x, pagecount: x.pageCount / 10 });

        });
    }

    set_page: (page: number) => void = (page: number) => {
        getArticles(page, 10, Publisher.type).then(x => {
            this.setState({ data: x, curpage: page });
        });
    }
    render(): React.ReactNode {


        return (
            <>
                <SubmenuTitle title="发布信息"></SubmenuTitle>
                <ul className="publisher">
                    {
                        this.state.data?.content?.map(x =>
                            <li>
                                <Link to={"/views/infos/publishdetial/" + x.id}>
                                    <span>{x.title}</span>
                                    <span>{x.date}</span>
                                </Link>
                            </li>
                        )
                    }
                </ul>

                <div className="page">
                    <Pager pageCount={this.state.pagecount} pageSize={10} curpage={0} onNavigatePage={this.set_page}></Pager>
                </div>
            </>
        );
    }

}