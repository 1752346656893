import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";
import { Home } from "../views/Home";
import { HomeInfo } from "../models/system";

export class AppRouter extends React.Component<HomeInfo> {
    render() {
        return (
            <BrowserRouter>
              <Switch>
                <Route path="/" component={ ()=><Home {...this.props}/>  }></Route>
              </Switch>
            </BrowserRouter>
        );
    }
}