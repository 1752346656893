import "../styles/base.scss";

import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { HomeBody } from "./Home/homeBody";
import { Link, Route } from "react-router-dom";
import { Switch } from "react-router";
import { Info } from "./info";
import { Show } from "./show";
import { Service } from "./service";
import { Saving } from "./Saving";
import { SavingType } from "./saving/SavingType";
import { Create } from "./Create";
import { Search } from "./Search";
import { Message } from "./Message";
import { ContactUs } from "./ContactUs";
import { SlidePicture, SlidePicturePropItem, SlidePictureProps } from "./components/SlidePicture";
import { HomeInfo } from "../models/system";

import home1 from "../assets/images/logo/home1.png";
import home2 from "../assets/images/logo/home2.png";
import home3 from "../assets/images/logo/home3.png";
import { SavingDetial } from "./saving/SavingDetial";
import { DetialProduct } from "./creates/detialProduct";


export class Home extends React.Component<HomeInfo> {


    private showad = false;
    private slidePicture: SlidePicture | null = null;
    componentDidMount(): void {

    }

    private switchHome = (idx: number) => {
        this.slidePicture?.switchImg(idx);
    }

    private craeteIcon = (icons: SlidePicturePropItem[]) => {
        let start = 0;
        let result: JSX.Element[] = [];
        for (let i = start; i < icons.length; i++) {

            result.push(<img src={icons[i].icon} alt={icons[i].icon} data-img={i + 1} onClick={() => this.switchHome(i)} />)
        }
        return result;
    }
    render() {
        this.showad = window.location.pathname.startsWith("/views/infos");

        let homeSlideItems: SlidePictureProps = this.props.homeImages == null || this.props.homeImages.length == 0 ?
            {
                item: [{ src: "/images/banner1.jpg", alt: "banner1", icon: "/images/home1.png" }
                    , { src: "/images/banner2.png", alt: "banner2", icon: "/images/home2.png" }
                    , { src: "/images/banner3.jpg", alt: "banner3", icon: "/images/home3.png" }],
                interval: this.props.slideInterval
            } : { item: this.props.homeImages.map(item => { return { src: item.pic, alt: item.title, icon: item.icon } as SlidePicturePropItem }), interval: this.props.slideInterval };

        let createItems = { item: [{ src: "/images/banner4.png", alt: "create1", icon: "" }] };//,{src:"/images/create2.jpg",alt:"create2",icon:""},{src:"/images/create3.jpg",alt:"create3",icon:""}]};
        let infoItems = { item: [{ src: "/images/info.png", alt: "create1", icon: "" }] };
        let servicesItems = { item: [{ src: "/images/service.png", alt: "create1", icon: "" }] };
        let collectItems = { item: [{ src: "/images/collect.png", alt: "create1", icon: "" }] };
        let showItems = { item: [{ src: "/images/show.png", alt: "create1", icon: "" }] };
        let virtualitems = { item: [{ src: "/images/virtual.png", alt: "virtual", icon: "" }] };
        return (
            <>
                <Header {...this.props} showad={this.showad}>
                    <Switch>
                        <Route path="/views/search" exact component={() => <></>}></Route>
                        <Route path="/views/message" exact component={() => <></>}></Route>
                        <Route path="/views/contactus" exact component={() => <></>}></Route>
                        <Route path="/views/creates" component={() => <SlidePicture {...createItems}></SlidePicture>}></Route>
                        <Route path="/views/infos" component={() => <SlidePicture {...infoItems}></SlidePicture>}></Route>
                        <Route path="/views/services" component={() => <SlidePicture {...servicesItems}></SlidePicture>}></Route>
                        <Route path="/views/virtual" component={() => <SlidePicture {...virtualitems}></SlidePicture>}></Route>
                        <Route path="/views/saving" component={() => <SlidePicture {...collectItems}>
                            <div className="btnSearch">
                                <Link to="/views/search">点击搜索</Link>
                            </div>

                        </SlidePicture>}></Route>
                        <Route path="/views/shows" component={() => <SlidePicture {...showItems}></SlidePicture>}></Route>
                        <Route path={"/"} component={() =>
                            <SlidePicture {...homeSlideItems} ref={el => this.slidePicture = el}  >
                                <div className="icon">

                                    {this.craeteIcon(homeSlideItems.item)}

                                </div>
                            </SlidePicture>
                        }></Route>

                    </Switch>
                </Header>

                <Switch>
                    <Route path="/" exact component={() => <HomeBody {...this.props} />}></Route>
                    <Route path="/views/infos/" component={Info}></Route>
                    <Route path={"/views/saving/savingtype/:id"} component={SavingType}></Route>
                    <Route path="/views/saving/savingDetial/:id" component={SavingDetial}></Route>
                    <Route path="/views/saving" component={Saving}></Route>
                    <Route path="/views/services" component={Service}></Route>
                    <Route path="/views/shows" component={Show}></Route>
                    <Route path={"/views/creates"} component={Create}></Route>
                    <Route path={"/views/search"} component={Search}></Route>
                    <Route path={"/views/message"} component={Message}></Route>
                    <Route path={"/views/contactus"} component={ContactUs}></Route>

                </Switch>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}