import "../styles/show.scss";
import React, { MouseEventHandler } from "react";
import { ReactNode } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Base } from "./shows/base";
import { Temp } from "./shows/temp";


export class Show extends React.Component {
    linkHandle: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.currentTarget.parentElement?.querySelectorAll("a").forEach((item) => { item.classList.remove("choiced") });
        e.currentTarget.className = "choiced";
        return true;
    }
    render(): ReactNode {
        return (
            <section id="showinfo" className="headerinfo info bg">
                <nav>
                    <div>
                        <Link to={"/views/shows/base"} onClick={this.linkHandle} className="choiced">临时展览</Link>
                        <Link to={"/views/shows/temp"} onClick={this.linkHandle}>临时展览</Link>
                    </div>
                </nav>
                <div className="article">
                    <Switch>
                        <Route path={"/views/shows"} exact component={Base}></Route>
                        <Route path={"/views/shows/base"} exact component={Base}></Route>
                        <Route path={"/views/shows/temp"} exact component={Temp}></Route>

                    </Switch>
                </div>
            </section>

        )

    }
}