import "../styles/base.scss";
import React from "react";
import phone  from "./../assets/images/phone.png";
import address from "./../assets/images/address.png";
import email  from "./../assets/images/email.png";
import { FooterInfo } from "../models/system";

export class Footer extends React.Component<FooterInfo> {
    render(): React.ReactNode {
        return (
            <footer>
                <div className="f1">
                    <a href="">帮助</a>
                    <a href="">隐私</a>
                    <a href="">条款</a>
                </div>
                <div className="icp"> {this.props.cpno?.split(";")?.join("|")}|{this.props.copyright}</div>
                <div className="address">
                    <div><img src={phone} alt=""></img><span>{this.props.phone}</span></div>
                    <div><img src={address} alt=""></img><span>{this.props.address}</span></div>
                    <div><img src={email} alt=""></img><span>{this.props.zipcode}</span></div>
                </div>
            </footer>
        )
    }
}