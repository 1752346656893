import React from "react";
import { HomeInfo } from "../../models/system";

import { SubmenuTitle } from "../components/SubmenuTitle";

export class Orgnization extends React.Component {

    render(): React.ReactNode {
        let departs: string[] = [];
        if (window.localStorage.getItem("sysinfo") != null) {
            departs = (JSON.parse(window.localStorage.getItem("sysinfo")!) as HomeInfo)?.departs?.split(";");
        }
        return (
            <>
                <SubmenuTitle title="组织架构"></SubmenuTitle>
                <div className="chart">
                    <div className="leader">
                        <div className="inner">
                            院长室
                        </div> 
                    </div>
                    <hr />
                    <div className="line">
                        <span className="fill"></span>
                        {
                            departs.slice(0,departs.length-1).map(x => <span />)
                        }
                        <span className="fill"></span>
                    </div>

                    <div className="depart">
                        <span className="fill"></span>
                        {departs.map(x => <span>{x}</span>)}
                        <span className="fill"></span>
                    </div>
                </div>
            </>
        );
    }

}