import React from "react";
import { getShowings } from "../../apis/api";
import { pageInfo, ShowInfo } from "../../models/system";
import { SubmenuTitle } from "../components/SubmenuTitle";
import { Reactangle } from "../infos/Reactangle";

export class Base extends React.Component {
    state = { infos: null as pageInfo<ShowInfo> | null }
    componentDidMount(): void {
        getShowings(0, 0, 100).then((data) => {

            this.setState({ infos: data });
            this.forceUpdate();
        });
    }



    render(): React.ReactNode {
        return (
            <>
                <SubmenuTitle title="临时展览"></SubmenuTitle>

                <div className="base">
                    {
                        this.state.infos?.content.map((item, index) =>
                            <Reactangle>
                                <div className="content">
                                    <div className="detial">
                                        <p className="name">{item.title}</p>
                                        <p className="showinfo">主办单位：盱眙县博物馆</p>
                                        <p className="showinfo">展出时间：{item.date}</p>
                                        <p className="showinfo">展出地点：{item.address}</p>
                                        <p className="desc">{item.desc}</p>
                                        <div className="btn">了解更多</div>
                                    </div>
                                    <div className="space"></div>
                                    <div className="img">
                                        <img src={item.pic} alt="" />
                                    </div>
                                </div>

                            </Reactangle>
                        )
                    }

                </div>
            </>
        )
    }
}