import "./styles/base.scss";
import React, { Component } from "react";
import { ConfigProvider } from "antd";
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import { AppRouter } from "./routers/route";
import {  SystemInfoProvider } from "./models/SysytemInfoContext";
import { HomeInfo, SystemInfo } from "./models/system";
import { getHomeInfo } from "./apis/api";

// let sys: SystemInfo = {
//   title: "盱眙县博物馆",
//   copyright: "©2022"
// };

moment.locale('zh-cn');
export class App extends Component {
 
  state = {sysInfo: null  as null | HomeInfo};
  // state = {context: undefined as SystemInfoWrapper | undefined};

  // loadContext = () => {
  //   if(window.sessionStorage.getItem("sysinfo")!=null){
  //     this.setState({context:JSON.parse(window.localStorage.getItem("sysinfo")!)});
  //   }
  //   else this.setState({context : sys});
  // }

  componentDidMount(): void {

    getHomeInfo().then((data) => {
      this.setState({sysInfo:data});
      window.localStorage.setItem("sysinfo",JSON.stringify(data));
    });

    // this.loadContext();
  }
  // updataContext = (sys: SystemInfo) => {
  //   this.setState({context: {...sys} });
  //   window.sessionStorage.setItem("sysinfo", JSON.stringify(sys));
    
  // }

  render() {
    window.document.title = this.state.sysInfo?.title!;
    return (
      <ConfigProvider locale={zh_CN}>
        <AppRouter {...this.state.sysInfo as any} />
      </ConfigProvider>);
  }
}


