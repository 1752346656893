import React from "react";
import { Title } from "../components/Title";

export class OpenTime extends React.Component {
    render(): React.ReactNode {
        return (

            <div className="openTime content">
                 <Title title="开馆时间"></Title>
              
                <div className="openTimeContent">
                    <div>8:30</div>
                    <div className="bar"></div>
                    <div>11:15</div>
                    <div className="bar"></div>
                    <div>11:45</div>
                    <div></div>
                    <div className="info">开放入馆时间</div>
                    <div></div>
                    <div className="info">停止入馆时间</div>
                    <div></div>
                    <div className="info">闭馆时间</div>
                    <div></div>
                    <div>14:00</div>
                    <div className="bar"></div>
                    <div>17:00</div>
                    <div className="bar"></div>
                    <div>17:30</div>
                    <div></div>
                </div>
                <div>
                    <p>注：本馆对社会各界及未成年人免费开放<br/>周二至周日正常开放，周一闭馆维护（法定节假日除外）</p>
                    <p className="notice">如有需要临时闭馆，届时将告示公众</p>
                </div>
            </div>

        )
    }
}