import "../../styles/services.scss";
import React from "react";
import { ReactNode } from "react";
import { SubmenuTitle } from "../components/SubmenuTitle";
import ProductImage from "../../assets/images/creates/ps.png";
import { getProducts } from "../../apis/api";
import { ProductInfo } from "../../models/system";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
//@ts-ignore
@withRouter
export class Shop extends React.Component<Partial<RouteComponentProps<any>>> {

    state = { data: [] as ProductInfo[], page: 0, size: 9, total: 0 }
    constructor(props: any) {
        super(props);
        getProducts(this.state.page, this.state.size).then((data) => {
            this.setState({ data: data.content, total: data.pageCount });
        })
    }






    render(): ReactNode {
        return (
            <>
                <div id="shop">
                    <SubmenuTitle title="商店介绍"></SubmenuTitle>
                    <div className="itemTitle">店铺名称</div>
                    <div className="browser" >时间：2022年3月14日    浏览次数：</div>

                    <div className="content">店铺描述店铺描述店铺描述店铺描述店铺描述店铺描述店铺描述店铺描述</div>


                </div>


            </>
        )
    }
}

