import React from "react";
import { ReactNode } from "react";
import { WithRouterProps } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getCollection } from "../../apis/api";
import { CollectionInfo, ProductInfo } from "../../models/system";
//@ts-ignore
@withRouter
export class SavingDetial extends React.Component<Partial<RouteComponentProps<{ id: string }>>>{

    state = { data: null as CollectionInfo | null }
    constructor(props: any) {
        super(props);

        getCollection(this.props.match!.params.id).then((data) => {
            this.setState({ data: data });
        });
    }
    // async getSnapshotBeforeUpdate() {
    //     let res = await getCollection(this.props.match!.params.id);
    //     this.setState({ data: res });
    //     window.alert(this.state.data?.title);
    // }
    render(): ReactNode {

        return (
            <section className="bg savingdetial">
                <div>
                    <img src={this.state.data?.pic}></img>
                </div>
                <div className="content">
                    <div className="name">【<span> {this.state.data?.title}</span>】</div>
                    <div className="time"><label>时代</label><span>{this.state.data?.time}</span></div>
                    <div><label>规格</label><span>{this.state.data?.specifications}</span></div>
                    <div className="text"><label>简介</label><span dangerouslySetInnerHTML={{ __html: this.state.data?.content ?? "" }}></span></div>
                </div>
            </section >
        )
    }
}
