

import "../styles/base.scss";
import React, { MouseEventHandler } from "react";
import logo from "./../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { FooterInfo, Menu } from "../models/system";

import { MenuSwitcher } from "../libs/MenuSwitcher";
export class Header extends React.Component<FooterInfo & { showad: boolean }> {
    private getMenus = (): Menu[] => [
        { title: "首页", src: "/", id: "home" },
        { title: "资讯", src: "/views/infos" },
        { title: "展览", src: "/views/shows" },
        { title: "馆藏", src: "/views/saving" },
        { title: "服务", src: "/views/services/1/教育活动" },
        { title: "文创", src: "/views/creates" },
        { title: "虚拟博物馆", src: (() => (this.props.virtualUrl ?? "") === "" ? "/views/virtual" : this.props.virtualUrl)(), isOutLink: (this.props.virtualUrl ?? "") !== "" },
    ];
    private menuSwitcher = new MenuSwitcher("choice", this.getMenus());

    componentDidMount(): void {

        this.menuSwitcher.load();
    }
    render() {
        return (
            <header>
                <div className="header">
                    <nav>
                        <div className="logo">
                            <img alt="" className="logo" src={logo}></img>
                        </div>
                        <div className="main">
                            {
                                this.menuSwitcher.craeteMenu()
                            }
                        </div>
                        <div className="right">
                            <Link to="/views/search">搜索</Link>
                            <Link to="">自愿者报名</Link>
                            <Link to={"/views/message"}>留言板</Link>
                            <span>|</span>
                            <Link to={"/views/contactus"}>联系我们</Link>
                            <span className="language">
                                <Link to={""}>中文</Link><span>|</span>
                                <Link to={""}>Eng</Link><span></span>

                            </span>
                        </div>

                    </nav>
                    {
                        this.props.showad ?
                            <div className="header ad">
                                {
                                    this.props.adInfos?.map(x => <div><img src={x.pic} alt=""></img>
                                        <span>{x.title}</span>
                                    </div>)
                                }

                            </div>
                            : <></>
                    }

                </div>
                {this.props.children}
            </header>
        );
    }
}