import React from "react";
import { SubmenuTitle } from "../components/SubmenuTitle";
import { Reactangle } from "./Reactangle";
import "../../styles/news.scss";
import { getNews } from "../../apis/api";
import { NewsInfo } from "../../models/system";
export class News extends React.Component {
    state={infos:[] as NewsInfo[]}
    componentDidMount(): void {
        getNews(0, 10,50).then((data) => {
        
            this.setState({infos:data});
          
        });
    }

    render(): React.ReactNode {
      
        return (
            <>
                <SubmenuTitle title="最新动态"></SubmenuTitle>
                <div className="news">

                    <div className="content">
                        <Reactangle>
                            <hr className="col"></hr>
                            <ul>
                                {
                                    this.state.infos?.map((item, index) => {
                                        return (
                                            <>
                                                <li className="year">
                                                <div className="space"></div>
                                                <div className="circle">{item.year}年</div>
                                                <hr />
                                                </li>
                                                {
                                                    item.infos?.map((info,idx) => {
                                                    
                                                        return (
                                                        <li className="content">
                                                        <span className="time">{info.date}</span>
                                                        <span className="circle2"></span>
                                                            <img src={info.pic}></img>
                                                            <p>
                                                                {info.title}
                                                            </p>
                                                        </li>)
                                                    })
                                                }
                                            </>
                                          
                                        )
                                    })
                                
                                }


                                {/* <li className="year">
                                    <div className="space"></div>
                                    <div className="circle">2002年</div>
                                    <hr />
                                </li>
                                <li className="content">

                                    <span className="time">2002年12月18日</span>
                                    <span className="circle2"></span>
                                    <img src=""></img>
                                    <p>
                                        2022年8月18日，国际博物馆日是，带你走进中国博物馆，了解中国博物馆的发展历程，感受中国博物馆的魅力。
                                    </p>
                                </li>
                                <li className="content">

                                    <span className="time">2002年12月18日</span>
                                    <span className="circle2"></span>
                                    <img src=""></img>
                                    <p>
                                        2022年8月18日，国际博物馆日是，带你走进中国博物馆，了解中国博物馆的发展历程，感受中国博物馆的魅力。
                                    </p>
                                </li>
                                <li className="year">
                                    <div className="space"></div>
                                    <div className="circle">2002年</div>
                                    <hr />
                                </li>
                                <li className="content">

                                    <span className="time">2002年12月18日</span>
                                    <span className="circle2"></span>
                                    <img src=""></img>
                                    <p>
                                        2022年8月18日，国际博物馆日是，带你走进中国博物馆，了解中国博物馆的发展历程，感受中国博物馆的魅力。
                                    </p>
                                </li> */}
                            </ul>
                        </Reactangle>
                    </div>

                </div>
            </>
        )
    }
}