import React from "react";
import leftbar from "../../assets/images/leftbar.png";
import rightbar from "../../assets/images/rightbar.png";
export class Reactangle extends React.Component{

    render(): React.ReactNode {
        return (
          
            <article className="infos">
                <div className="r1 c1" />
                <div className="r1 c2" />
                <div className="r1 c3" />
                <div className="r1 c4" />
                <div className="r1 c" />
                <div className="r1 c_4" />
                <div className="r1 c_3" />
                <div className="r1 c_2" />
                <div className="r1 c_1" />


                <div className="r2 c1" />
                <div className="r2 c2" />
                <div className="r2 c3" />
                <div className="r2 c4" />
                <div className="r2 c" />
                <div className="r2 c_4" />
                <div className="r2 c_3" />
                <div className="r2 c_2" />
                <div className="r2 c_1" />


                <div className="r3 c1" />
                <div className="r3 c2" />
                <div className="r3 c3" />
                <div className="r3 c4" />
                <div className="r3 c" />
                <div className="r3 c_4" />
                <div className="r3 c_3" />
                <div className="r3 c_2" />
                <div className="r3 c_1" />



                <div className="r4 c1" />
                <div className="r c rectContent" >
                    {this.props.children}
                </div>
         
                <div className="r4 c_1" />


                <div className="r c1" />
               
                <div className="r c_1" />



                <div className="r_4 c1" />
                {/* <div className="r_4 c2" />
                <div className="r_4 c3" />
                <div className="r_4 c4" />
                <div className="r_3 c" />
                <div className="r_4 c_4" />
                <div className="r_4 c_3" />
                <div className="r_4 c_2" /> */}
                <div className="r_4 c_1" />
             
                
                <div className="r_3 c1" />
                <div className="r_3 c2" />
                <div className="r_3 c3" />
                <div className="r_3 c4" />
                <div className="r_3 c" />
                <div className="r_3 c_4" />
                <div className="r_3 c_3" />
                <div className="r_3 c_2" />
                <div className="r_3 c_1" />

                <div className="r_2 c1" />
                <div className="r_2 c2" />
                <div className="r_2 c3" />
                <div className="r_2 c4" />
                <div className="r_2 c" />
                <div className="r_2 c_4" />
                <div className="r_2 c_3" />
                <div className="r_2 c_2" />
                <div className="r_2 c_1" />



                <div className="r_1 c1" />
                <div className="r_1 c2" />
                <div className="r_1 c3" />
                <div className="r_1 c4" />
                <div className="r_1 c" />
                <div className="r_1 c_4" />
                <div className="r_1 c_3" />
                <div className="r_1 c_2" />
                <div className="r_1 c_1" />
            </article>
           
        )
    }
}