import React from "react";
import { ReactNode } from "react";
export interface SlidePicturePropItem {
    src: string;
    alt: string;
    icon: string;
  
}
export interface SlidePictureProps {
    item: SlidePicturePropItem[],
    interval?: number;
}


export class SlidePicture extends React.Component<SlidePictureProps>{
    state = { current: (this.props.item!.length > 0 ? this.props.item[0] : null) as null | SlidePicturePropItem, curIndex: 0 as number };
    componentDidMount(): void {
    
        if (this.props.interval != null) {
            setInterval(() => {
                this.setState({ current: this.props.item[this.state.curIndex] });
                this.setState({ curIndex: (this.state.curIndex + 1) % this.props.item.length });
            }, this.props.interval*1000);
        }
    }
    public switchImg(idx: number) {

        this.setState({ current: this.props.item[idx], curIndex: idx });
    }
    render(): ReactNode {

        return (
            <>
                <div className="banner">
                    <img src={this.state.current!.src} alt={this.state.current!.alt}></img>
                </div>
                {this.props.children}
            </>
        );
    }
}