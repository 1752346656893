import "../../styles/services.scss";
import React from "react";
import { ReactNode } from "react";
import { Title } from "../components/Title";
import { SubmenuTitle } from "../components/SubmenuTitle";
import { getVideos, VideoInfo } from "../../apis/api";
import { pageInfo } from "../../models/system";


export class Video extends React.Component {
    state = { data: null as pageInfo<VideoInfo> | null, curpage: 0, pagesize: 12 };
    async componentDidMount() {

        this.setState({ data: await getVideos(0, this.state.pagesize, 0) });

    }
    render(): ReactNode {
        return (
            <>
                <SubmenuTitle title="精彩视频"></SubmenuTitle>
                <div className="video">

                    {
                        this.state.data?.content?.map(x => (
                            <div>
                                <video src={x.url} controls></video>
                                <div className="videoTitle"> {x.title}
                                </div>
                            </div>
                        ))

                    }


                </div>
            </>
        )
    }
}