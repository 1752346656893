import React from "react";
import { getShowings } from "../../apis/api";
import { pageInfo, ShowInfo } from "../../models/system";
import { SubmenuTitle } from "../components/SubmenuTitle";


export class Temp extends React.Component {
    state = { infos: null as pageInfo<ShowInfo> | null }
    componentDidMount(): void {
        getShowings(1, 0, 100).then((data) => {
            this.setState({ infos: data });

        });
    }
    render(): React.ReactNode {
        return (
            <>
                <SubmenuTitle title="临时展览"></SubmenuTitle>
                <div className="temp">

                    {
                        this.state.infos?.content?.map((item, index) => {
                            return (
                                <div>

                                    <div className="temp_show_content">
                                        <div className="temp_show_content_title">{item.title}</div>

                                        <div className="temp_show_content_text">{item.desc}</div>
                                        <div className="temp_show_content_img">
                                            <img src={item.pic} alt=""></img>
                                        </div>
                                    </div>
                                </div>);

                        })
                    }

                </div>
            </>
        )
    }
}