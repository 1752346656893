import React from "react";
import "../../styles/saving.scss";


import { Link, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { SavingDetial } from "./SavingDetial";
import { getCollections } from "../../apis/api";
import { CollectionInfo } from "../../models/system";
import { StaticContext } from "react-router";




//@ts-ignore
@withRouter
export class SavingType extends React.Component<Partial<RouteComponentProps<{ id: string }>>>{
    state = { data: [] as CollectionInfo[], current: 0 as number, pagecount: 0 as number }
    constructor(props: any) {
        super(props);

        var id = parseInt(this.props.match!.params.id);



        getCollections(id, this.state.current, 15).then(x => {

            this.setState({ data: x.content });

        });

    }
    componentDidMount(): void {

    }
    componentWillReceiveProps(nextProps: Readonly<Partial<RouteComponentProps<{ id: string; }, StaticContext, unknown>>>, nextContext: any): void {
        if (nextProps.match?.params.id != this.props.match?.params.id) {
            var id = parseInt(nextProps.match!.params.id);
            getCollections(id, this.state.current, 15).then(x => {
                this.setState({ data: x.content });
            });
        }
    }
    update(): void {
        let child = document.querySelector("#submenu")!.children;
        for (let i = 0; i < child.length; i++) {
            child[i].classList.remove("choiced");
        }
        var id = parseInt(this.props.match!.params.id);

        document.querySelector("#submenu")!.children[id].classList.add("choiced");
    }


    render() {


        // document.querySelector("#submenu")!.children[id].classList.add("choiced");
        return (
            <section className="savinginfo  headerinfo info bg">
                <nav>
                    <div id="submenu" ref={() => this.update()}>
                        <Link to={"/views/saving/savingType/0"}>陶器</Link>
                        <Link to={"/views/saving/savingType/1"}>玉器</Link>
                        <Link to={"/views/saving/savingType/2"}>铜器</Link>
                        <Link to={"/views/saving/savingType/3"}>金银器</Link>
                        <Link to={"/views/saving/savingType/4"}>漆器</Link>
                        <Link to={"/views/saving/savingType/5"}>瓷器</Link>
                    </div>
                </nav>

                <div className="savingitems">
                    {
                        this.state.data.map((x, idx) =>

                            <div onClick={() => window.location.href = `../SavingDetial/${x.id}`}>
                                <img src={x.pic} />
                                <div>{x.title}</div>
                            </div>


                        )
                    }
                </div>

            </section >
        )
    }
}


