import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { Menu } from "../models/system";
export class MenuSwitcher {

    linkHandle: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.currentTarget.parentElement?.querySelectorAll("a").forEach((item) => { item.classList.remove(this.clazz) });
        e.currentTarget.className = this.clazz;
        return true;
    }

    constructor(private clazz: string, private menus: Menu[], private prix = "", private root = "/") {

    }

    load(): void {
        this.menus.forEach((item, index) => {
            if (window.location.pathname !== this.root) {
                if (window.location.pathname.startsWith(item.src!) && item.src !== this.root) {
                    document.getElementById(this.prix + "_menu_" + index)?.classList.add(this.clazz);
                }
            }
            else {
                document.getElementById(this.prix + "_menu_0")?.classList.add(this.clazz);
            }
        })
    }

    craeteMenu(): JSX.Element {
        return (
            <>
                {this.menus.map((item, index) => {
                    return (item.isOutLink ?? false) ?
                        <a href={item.src} data-href={item.src} id={this.prix + "_menu_" + index} key={index} onClick={this.linkHandle}>{item.title}</a>
                        : <Link to={item.src!} id={this.prix + "_menu_" + index} className={index == 0 ? this.clazz : ""} key={index} onClick={this.linkHandle}>{item.title}</Link>
                })}
            </>);
    }
} 